<template>
  <a-spin :spinning="spinning">
    <ele-table :columns="columns" :table-data="tableData" :total-items="totalItems" :is-full="true"
      :actionsType="actionsType" :current-page="pageNumber" :hide-row-selection="true" :pageSizes="pageSizeOptions"
      :pageSize="request.maxResultCount" @emitRefreshData="getData" @emitOnPageChange="updatePageChange"
      @emitShowSizeChange="updateShowSizeChange">
      <a-row :gutter="24">
        <!-- 搜索 -->
        <a-col :xs="{ span: 8 }" :sm="{ span: 6 }" :md="{ span: 5 }" :lg="{ span: 4 }">
          <div class="colbox">
            <a-input v-if="isGranted('Pages.LGModul.SignAccount.Query')" name="filterText" :placeholder="l('请输入账户名称')"
              enterButton v-model.trim="filterText" v-decorator="['filterText']" />
          </div>
        </a-col>
        <a-col :xs="{ span: 8 }" :sm="{ span: 6 }" :md="{ span: 5 }" :lg="{ span: 4 }">
          <div class="colbox">
            <a-select name="customerId" v-decorator="['customerId']" :placeholder="l('请选择客户')" v-model="customerId"
              allowClear :defaultValue="null" mode="default" :options="customerData" showSearch style="width: 100%">
            </a-select>
          </div>
        </a-col>
        <a-col :xs="{ span: 4 }" :sm="{ span: 4 }" :md="{ span: 4 }" :lg="{ span: 4 }">
          <a-button type="primary" @click="searchData" icon="search">查询</a-button>
          <a-button type="default" @click="resetForm">重置</a-button>
        </a-col>
        <a-col :xs="{ span: 4 }" :sm="{ span: 8 }" :md="{ span: 10 }" :lg="{ span: 12 }" style="text-align: right">
          <a-button :type="'primary'" @click="createAccount" v-if="isGranted('Pages.LGModul.SignAccount.Save')">
            <span>创建账户</span>
          </a-button>
        </a-col>
      </a-row>
    </ele-table>
  </a-spin>
</template>
<script>
import EleTable from "@/components/ele-table";
import { AppComponentBase } from "@/shared/component-base";
import { ModalHelper } from "@/shared/helpers";
import AddOrEdit from './components/save.vue';
import SalaryRecord from './components/salaryrecord.vue';
import DeductRecord from './components/deductrecord.vue';
import DownloadPayRecord from './components/downloadPayRecord.vue';
export default {
  mixins: [AppComponentBase],
  name: 'customer-account',
  components: {
    EleTable
  },
  data () {
    return {
      spinning: false,
      tableData: [],
      columns: [],
      // 总数
      totalItems: 0,
      // 当前页码
      pageNumber: 1,
      // 共多少页
      totalPages: 1,
      // 条数显示范围
      pagerange: [1, 1],
      customerData: [],
      // 显示条数
      pageSizeOptions: ['10', '20', '50', '100', '200'],
      request: { sorting: "", maxResultCount: 10, skipCount: 0 },
      customerId: undefined,
      actionsType: null,
      filterText: '',
    }
  },
  created () {
    this.initColumns();
    this.initActionsType();
    this.getCustomerSelectView();
    setTimeout(() => {
      this.getData();
    }, 500);
  },
  methods: {
    createAccount () {
      ModalHelper.create(AddOrEdit,
        {
          id: null,
          customers: this.customerData
        },
        {
          isChange: true,
          width: "850px",
        }
      ).subscribe((res) => {
        if (res) {
          this.getData();
        }
      });
    },
    getData () {
      this.spinning = true;
      const options = {
        url: `${this.$apiUrl}/api/services/app/CustomerProvideConfig/AccountList`,
        method: 'POST',
        data: {
          filterText: this.filterText,
          sorting: this.request.sorting,
          maxResultCount: this.request.maxResultCount,
          skipCount: this.request.skipCount,
          customerId: this.customerId
        },
        headers: {
          "Content-Type": "application/json-patch+json",
        },
      };
      this.$api.request(options)
        .then(response => {
          if (response.status !== 200) {
            abp.message.error(this.l("服务异常，请稍后重试"));
            return;
          }
          const res = response.data;
          this.tableData = res.items;
          this.totalItems = res.totalCount;
          this.totalPages = Math.ceil(
            res.totalCount / this.request.maxResultCount
          );
          this.pagerange = [
            (this.pageNumber - 1) * this.request.maxResultCount + 1,
            this.pageNumber * this.request.maxResultCount,
          ];
        }).catch(error => {
          console.error(error);
          abp.message.warn(this.l("DefaultErrorMessage"));
        }).finally(() => {
          this.spinning = false;
        });
    },
    updatePageChange (info) {
      const { page, skipCount, pageSize } = info;
      this.request.skipCount = skipCount * (page - 1);
      this.request.maxResultCount = pageSize;
      this.getData();
    },
    updateShowSizeChange (info) {
      const { current, size } = info;
      this.request.maxResultCount = size;
      this.request.skipCount = 0;
      this.getData();
    },
    searchData () {
      this.request.skipCount = 0;
      this.getData();
    },
    initActionsType () {
      let _this = this;
      let obj = {
        type: "customerAccount",
        isShow: true,
        fns: {
          detailList: [{
            name: this.l("编辑"),
            icon: "edit",
            granted: this.isGranted("Pages.LGModul.SignAccount.Save"),
            fn: (row) => {
              console.warn('edit button click', row);
              ModalHelper.create(AddOrEdit,
                {
                  id: row.id,
                  customers: this.customerData
                },
                {
                  isChange: true,
                  width: "850px",
                }
              ).subscribe((res) => {
                if (res) {
                  this.getData();
                }
              });
            }
          }],
          dropList: [
            {
              granted: this.isGranted("Pages.LGModul.SignAccount.QueryAmount"),
              name: this.l("刷新余额"),
              icon: "pay-circle",
              fn: (data) => {
                const options = {
                  url: `${_this.$apiUrl}/api/services/app/CustomerProvideConfig/FlushAccountRemainAmount`,
                  method: 'GET',
                  params: {
                    id: data.id
                  }
                };
                _this.$api.request(options)
                  .then(response => {
                    if (response.status !== 200) {
                      abp.message.error(_this.l("服务异常，请稍后重试"));
                      return;
                    }
                    abp.message.success(_this.l("刷新成功"));
                    _this.tableData = _this.tableData.map(it => {
                      if (it.id === data.id) {
                        it.remainAmount = response.data;
                      }
                      return it;
                    });
                  }).catch(e => {
                    console.error(e);
                    abp.message.error(_this.l("服务异常，请稍后重试"));
                  });
              },
            },
            {
              granted: this.isGranted("Pages.LGModul.SignAccount.SalaryRecord"),
              name: this.l("发放记录"),
              icon: "profile",
              fn: (data) => {
                ModalHelper.create(SalaryRecord,
                  {
                    id: data.id
                  },
                  {
                    isChange: true,
                    width: "1200px",
                  }
                ).subscribe((res) => {
                });
              },
            },
            {
              granted: this.isGranted("Pages.LGModul.SignAccount.FeeAutoDeductList"),
              name: this.l("手续费扣除记录"),
              icon: "pay-circle",
              fn: (data) => {
                ModalHelper.create(DeductRecord,
                  {
                    id: data.id
                  },
                  {
                    isChange: true,
                    width: "1200px",
                  }
                ).subscribe((res) => {
                });
              },
            },
             {
              granted: this.isGranted("Pages.LGModul.SignAccount.QueryDownloadReTask"),
              name: this.l("交易流水下载"),
              icon: "pay-circle",
              fn: (data) => {
                ModalHelper.create(DownloadPayRecord,
                  {
                    id: data.appId
                  },
                  {
                    isChange: true,
                    width: "1200px",
                  }
                ).subscribe((res) => {
                });
              },
            }
          ],
        },
        width: '100px',
        minWidth: '100px',
      };
      this.actionsType = obj;
    },
    initColumns () {
      const _this = this;
      this.columns = [
        {
          title: this.l("账户卡号"),
          dataIndex: "accountNo",
          sorter: false,
          align: "center",
          ellipsis: true,
          width: 120,
          scopedSlots: { customRender: "accountNo" },
        },
        {
          title: this.l("发放通道名称"),
          dataIndex: "name",
          sorter: false,
          align: "center",
          ellipsis: true,
          width: 150,
          scopedSlots: { customRender: "name" },
        },
        {
          title: this.l("账户类型"),
          dataIndex: 'accountTypeStr',
          sorter: false,
          align: 'center',
          ellipsis: true,
          width: 100,
          scopedSlots: { customRender: 'accountTypeStr' },
        },
        {
          title: this.l("AppId"),
          dataIndex: 'appId',
          sorter: false,
          align: 'center',
          ellipsis: true,
          width: 100,
          scopedSlots: { customRender: 'appId' },
        },
        {
          title: this.l("自动扣手续费"),
          dataIndex: 'autoDeductFee',
          sorter: false,
          align: 'center',
          ellipsis: true,
          width: 100,
          scopedSlots: {
            customRender: 'autoDeductFee'
          },
          type: "tag",
          getColor: (value) => {
            var color = '#2db7f5';
            if (value === 1) {
              color = "#f50";
            }
            return color;
          },
          getText: (value) => {
            var txt = '关闭';
            if (value === 1) {
              1
              txt = '自动扣费';
            }
            return txt
          }
        },
        {
          title: this.l("服务费率"),
          dataIndex: 'feeRate',
          sorter: false,
          align: 'center',
          ellipsis: true,
          type: "custom",
          width: 100,
          scopedSlots: {
            customRender: 'feeRate'
          },
          customRender (text, record) {
            if (text) {
              return `${(text * 100).toFixed(0)}%`;
            }
            return '0';
          }
        },
        {
          title: this.l("所属组织架构"),
          dataIndex: 'organizationName',
          sorter: false,
          align: 'center',
          ellipsis: true,
          scopedSlots: { customRender: 'organizationName' },
        },
        {
          title: this.l("所属客户"),
          dataIndex: 'customerName',
          sorter: false,
          align: 'center',
          ellipsis: true,
          scopedSlots: { customRender: 'customerName' },
        },
        {
          title: this.l("账户余额"),
          dataIndex: 'remainAmount',
          sorter: false,
          align: 'center',
          ellipsis: true,
          scopedSlots: { customRender: 'remainAmount' },
        },
        {
          title: this.l("余额提醒阈值"),
          dataIndex: 'warningAmount',
          sorter: false,
          align: 'center',
          ellipsis: true,
          scopedSlots: { customRender: 'warningAmount' },
        },
        {
          title: this.l("余额提醒邮箱"),
          dataIndex: 'notifyEmail',
          sorter: false,
          align: 'center',
          ellipsis: true,
          scopedSlots: { customRender: 'notifyEmail' },
        },
        {
          title: this.l("创建人"),
          dataIndex: 'creator',
          sorter: false,
          align: 'center',
          ellipsis: true,
          width: 100,
          scopedSlots: { customRender: 'creator' },
        },
        {
          title: this.l("创建时间"),
          dataIndex: 'createdTime',
          sorter: false,
          align: 'center',
          ellipsis: true,
          width: 120,
          scopedSlots: { customRender: 'createdTime' },
        }
      ];
    },
    getCustomerSelectView () {
      if (this.customerData.length > 0) {
        return;
      }
      const options = {
        url: `${this.$apiUrl}/api/services/app/Customer/GetCustomerOption`,
        method: 'GET'
      };
      this.$api.request(options)
        .then(response => {
          console.warn(response);
          if (response.status !== 200) {
            return;
          }
          const res = response.data;
          this.customerData = res;
        }).catch(e => {
          console.error(e);
        });
    },
    resetForm () {
      this.filterText = null;
      this.customerId = undefined;
    }
  }
}
</script>

<style>
.colbox {
  width: 100%;
}
</style>
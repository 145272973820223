<template>
  <a-spin :spinning="spinning">
    <div class="modal-header">
      <div class="modal-title">
        <span>账户流水</span>
      </div>
    </div>
    <div>
      <ele-table :columns="columns" :table-data="tableData" :total-items="totalItems" :is-full="true" maxHeight=""
        :actionsType="actionsType" :current-page="pageNumber" :hide-row-selection="true" :pageSizes="pageSizeOptions"
        :handleHeight="100" :pageSize="request.maxResultCount" @emitRefreshData="getData"
        @emitOnPageChange="updatePageChange" @emitShowSizeChange="updateShowSizeChange">
        <a-row :gutter="24">
          <!-- 搜索 -->
          <a-col :xs="{ span: 8 }" :sm="{ span: 6 }" :md="{ span: 5 }" :lg="{ span: 4 }">
            <div class="colbox">
              <a-input name="filterText" :placeholder="l('请输入发放批次号')" enterButton v-model.trim="filterText"
                v-decorator="['filterText']" />
            </div>
          </a-col>
          <a-col :xs="{ span: 8 }" :sm="{ span: 6 }" :md="{ span: 5 }" :lg="{ span: 4 }">
            <div class="colbox">
              <a-select name="customerId" v-decorator="['customerId', {}]" :placeholder="l('请选择客户')" v-model="customerId"
                allowClear :defaultValue="null" mode="default" :options="customerData" showSearch style="width: 100%">
              </a-select>
            </div>
          </a-col>
          <a-col :xs="{ span: 4 }" :sm="{ span: 4 }" :md="{ span: 4 }" :lg="{ span: 4 }">
            <a-button type="primary" @click="searchData" icon="search">查询</a-button>
          </a-col>
        </a-row>
      </ele-table>
    </div>
  </a-spin>
</template>

<script>
import EleTable from "@/components/ele-table";
import { ModalComponentBase } from "@/shared/component-base";

export default {
  mixins: [ModalComponentBase],
  components: {
    EleTable
  },
  name: "create-or-edit-signaccount",
  data () {
    return {
      spinning: false,
      columns: [],
      tableData: [],
      totalItems: 0,
      pageNumber: 1,
      actionsType: null,
      pageSizeOptions: ['10', '20', '50', '100', '200'],
      request: { sorting: "", maxResultCount: 10, skipCount: 0 },
      filterText: '',
      customerData: [],
      customerId: null,
    };
  },
  created () {
    this.fullData(); // 模态框必须,填充数据到data字段
    this.initColumns();
    this.getData();
  },
  mounted () {
  },
  methods: {
    updatePageChange (info) {
      const { page, skipCount, pageSize } = info;
      this.request.skipCount = skipCount * (page - 1);
      this.request.maxResultCount = pageSize;
      this.getData();
    },
    updateShowSizeChange (info) {
      const { current, size } = info;
      this.request.maxResultCount = size;
      this.request.skipCount = 0;
      this.getData();
    },
    getData () {
      const options = {
        url: `${this.$apiUrl}/api/services/app/CustomerProvideConfig/GetAccountSalaryRecordByPage`,
        method: 'POST',
        data: {
          ...this.request,
          AccountId: this.id,
          batchNo: this.filterText,
          customerId: this.customerId
        }
      };
      this.$api.request(options)
        .then(response => {
          if (response.status !== 200) {
            abp.message.error(this.l("服务异常，请稍后重试"));
            return;
          }
          const res = response.data;
          this.tableData = res.items;
          this.totalItems = res.totalCount;
          this.totalPages = Math.ceil(
            res.totalCount / this.request.maxResultCount
          );
          this.pagerange = [
            (this.pageNumber - 1) * this.request.maxResultCount + 1,
            this.pageNumber * this.request.maxResultCount,
          ];
        }).catch(e => {
          console.error(e);
          abp.message.warn(this.l("DefaultErrorMessage"));
        })
    },
    searchData () {
      this.request.skipCount = 0;
      this.getData();
    },
    initColumns () {
      this.columns = [
        {
          title: this.l("批次号"),
          dataIndex: "batchNo",
          sorter: false,
          align: "center",
          ellipsis: true,
          width: 120,
          scopedSlots: { customRender: "batchNo" },
        },
        {
          title: this.l("明细号"),
          dataIndex: 'payNo',
          sorter: false,
          align: 'center',
          ellipsis: true,
          width: 120,
          scopedSlots: { customRender: 'payNo' },
        },
        {
          title: this.l("项目名称"),
          dataIndex: 'projectName',
          sorter: false,
          align: 'center',
          ellipsis: true,
          scopedSlots: { customRender: 'projectName' },
        },
        {
          title: this.l("发放账户"),
          dataIndex: 'account',
          sorter: false,
          align: 'center',
          ellipsis: true,
          scopedSlots: {
            customRender: 'account'
          }
        },
        {
          title: this.l("姓名"),
          dataIndex: 'realName',
          sorter: false,
          align: 'center',
          ellipsis: true,
          width: 90,
          scopedSlots: {
            customRender: 'realName'
          }
        },
        {
          title: this.l("手机号"),
          dataIndex: 'phoneNumber',
          sorter: false,
          align: 'center',
          ellipsis: true,
          width: 90,
          scopedSlots: { customRender: 'phoneNumber' },
        },
        {
          title: this.l("银行名称"),
          dataIndex: 'bankName',
          sorter: false,
          align: 'center',
          ellipsis: true,
          width: 100,
          scopedSlots: { customRender: 'bankName' },
        },
        {
          title: this.l("银行卡号"),
          dataIndex: 'bankNo',
          sorter: false,
          align: 'center',
          width: 100,
          ellipsis: true,
          scopedSlots: { customRender: 'bankNo' },
        },
        {
          title: this.l("金额"),
          dataIndex: 'amount',
          sorter: false,
          align: 'center',
          width: 80,
          ellipsis: true,
          scopedSlots: { customRender: 'amount' },
        },
        {
          title: this.l("发放时间"),
          dataIndex: 'payTime',
          sorter: false,
          align: 'center',
          ellipsis: true,
          scopedSlots: { customRender: 'payTime' },
        },
        {
          title: this.l("状态"),
          dataIndex: 'statusStr',
          sorter: false,
          align: 'center',
          ellipsis: true,
          width: 100,
          scopedSlots: { customRender: 'statusStr' },
        }
      ];
      const that = this;
      this.actionsType = {
        type: "customerAccount",
        isShow: true,
        fns: {
          detailList: [{
            name: this.l("批次回单"),
            icon: "edit",
            granted: this.isGranted("Pages.LGModul.SignAccount.BatchReceipt"),
            fn: (row) => {
              that.downloadReceiptFile(row.batchId, 1);
            },
            showFunc: (row) => {
              return row.status === 'Succeed';
            }
          }, {
            name: this.l("明细回单"),
            icon: "edit",
            granted: this.isGranted("Pages.LGModul.SignAccount.PayReceipt"),
            fn: (row) => {
              that.downloadReceiptFile(row.id, 2);
            },
            showFunc: (row) => {
              return row.status === 'Succeed';
            }
          }]
        },
        width: '100px',
        minWidth: '100px',
      }
    },
    downloadReceiptFile (id, type) {
      this.spinning = true;
      const options = {
        url: `${this.$apiUrl}/api/services/app/CustomerProvideConfig/DownloadReceiptFile`,
        method: "POST",
        data: {
          id: id,
          type: type
        }
      };
      this.$api.request(options)
        .then(response => {
          if (response.status !== 200) {
            abp.message.error(this.l("服务异常，请稍后重试"));
            return;
          }
          const res = response.data;
          if (res.indexOf('http') >= 0) {
            window.open(res);
            return;
          }
          abp.message.warn('回单下载失败');
        }).finally(() => {
          this.spinning = false;
        });
    }
  },
};
</script>

<style lang="less" scoped>
.btn--container .ant-form-item-children {
  display: block;
  text-align: center;
}

.pleaseSelect-text {
  font-size: 14px;
  padding: 0 14px;
  text-align: center;
  color: rgba(0, 0, 0, 0.65);
  font-weight: 400;
  line-height: 30px;
  margin-bottom: 0;
}
</style>
